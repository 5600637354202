/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Eriza from "@components/sharedComponents/customersLogos/logos/3eriza.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Culqi from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import IttsaBus from "@components/sharedComponents/customersLogos/logos/IttsaBus.svg"
import Chiolecca from "@components/sharedComponents/customersLogos/logos/Chiolecca.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/api-sms.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/sms-opt-in.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/sms-marketing.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/whatsapp-sms.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/proceso-verificacion-usuarios.jpg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageChannels/pageSms/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageChannels/pageSms/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageChannels/pageSms/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageChannels/pageSms/images/accordionLeft/imageFour.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageSMS.webp"


import { TitleUnderlineSmsOne, TitleUnderlineSmsTwo } from "@components/pageChannels/components/intro/titleUnderlineSms"
import imageStartSms from "@components/pageChannels/components/intro/images/imageStartSms.webp"

//image indrustias
import banca from "@components/pageChannels/pageSms/industries/images/banca.png"
import salud from "@components/pageChannels/pageSms/industries/images/salud.png"
import ecommerce from "@components/pageChannels/pageSms/industries/images/ecommerce.png"
import educacion from "@components/pageChannels/pageSms/industries/images/educacion.png"
import concesionaria from "@components/pageChannels/pageSms/industries/images/concesionaria.png"
import inmobiliaria from "@components/pageChannels/pageSms/industries/images/inmobiliaria.png"

import ImageVerticalOne from "@components/pageChannels/pageSms/images/imagesNavbarVertical/imageOne.webp"
import ImageVerticalTwo from "@components/pageChannels/pageSms/images/imagesNavbarVertical/imageTwo.webp"
import ImageVerticalThree from "@components/pageChannels/pageSms/images/imagesNavbarVertical/imageThree.webp"
import ImageVerticalFour from "@components/pageChannels/pageSms/images/imagesNavbarVertical/imageFour.webp"
import ImageVerticalFive from "@components/pageChannels/pageSms/images/imagesNavbarVertical/imageFive.webp"

//iconos poteciamos operaciones
import imageConversionsOne from "@components/pageChannels/pageSms/conversions/images/imageOne.webp"
import imageConversionsTwo from "@components/pageChannels/pageSms/conversions/images/imageTwo.webp"
import imageConversionsThree from "@components/pageChannels/pageSms/conversions/images/imageThree.webp"
import imageConversionsFour from "@components/pageChannels/pageSms/conversions/images/imageFour.webp"


const titleStart = (
  <p>
    Plataforma para  <TitleUnderlineSmsOne underline="SMS" /> <TitleUnderlineSmsTwo underline="con IA" /> para enviar mensajes más inteligentes y a gran escala
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Configura campañas masivas de SMS utilizando la IA, tanto para crear textos únicos como para segmentar y personalizar la comunicación con los clientes. Crea workflows para automatizar tareas y enviar mensajes al momento adecuado.
        <br /><br />
        Permite que tus clientes respondan tus mensajes para crear una interacción bidireccional. Además, crea estrategias A/B y segmentaciones para personalizar tus mensajes.
      </p>
    ),
    image: imageStartSms,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Eriza,
    Conexo,
    Culqi,
    Alfin,
    IttsaBus,
    Chiolecca,
  ],
  customersTablet: {
    arrayOne: [Eriza, Conexo, Culqi, Alfin, IttsaBus, Chiolecca,]
  },
  articles: [
    {
      image: article1,
      title: "Cómo elegir un proveedor de API SMS para envíos a gran escala",
      textbody:
        "Descubre claves prácticas para seleccionar el mejor proveedor de API SMS y maximizar tus envíos masivos de mensajes.",
      link: "https://beexcc.com/blog/api-sms/",
      card: true,
    },
    {
      image: article2,
      title: "SMS opt-in: 8 mejores prácticas para aumentar tu base de datos",
      textbody:
        "Descubre cómo implementar estrategias efectivas para obtener consentimiento y expandir tu lista de contactos a través del SMS.",
      link: "https://beexcc.com/blog/sms-opt-in/",
      card: true,
    },
    {
      image: article3,
      title: "Diseña una estrategia de SMS marketing en simples pasos",
      textbody:
        "Maximiza el alcance y la participación del cliente con una estrategia de SMS marketing efectiva en solo unos pasos sencillos.",
      link: "https://beexcc.com/blog/sms-marketing/",
    },
    {
      image: article4,
      title: "WhatsApp vs SMS: ¿Cuál es más efectivo para tu empresa?",
      textbody:
        "Creamos un WhatsApp vs SMS para conocer sus fortalezas y debilidades de cada uno y así sepas cuál es el ideal para tu empresa.",
      link: "https://beexcc.com/blog/whatsapp-sms/",
    },
    {
      image: article5,
      title: "Cómo crear mejores procesos de verificación de usuarios y CX",
      textbody:
        "Evita procesos engorrosos en la verificación de usuarios con estos 6 consejos enfocados en la experiencia de cliente (CX)",
      link: "https://beexcc.com/blog/proceso-verificacion-usuarios/",
    },
  ],
  contacts: {
    title: "Potencia el alcance de tus campañas de SMS marketing",
    textbody: "Cuéntanos sobre los procesos de SMS marketing de tu empresa y nuestro equipo te brindará un feedback detallado para potenciar tus operaciones.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  accordionLeft: [
    {
      title: "Workflows automatizados",
      description: "<p>Crea workflows automatizados que gestionan y envían mensajes SMS basados en disparadores específicos, como comportamientos del cliente, acciones previas o recordatorios de pagos. Asegura que tus mensajes lleguen en el momento adecuado sin necesidad de intervención manual.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Inteligencia artificial",
      description: "<p>Optimiza tus campañas de SMS con la potencia de la inteligencia artificial. Analiza grandes volúmenes de datos para predecir el mejor momento de envío, segmentar audiencias de manera más precisa y personalizar el contenido de los mensajes.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Mensajería SMS bidireccional",
      description: "<p>Permite a tus clientes responder a tus mensajes, proporcionando una vía directa para la retroalimentación, atención al cliente y participación en tiempo real, mejorando significativamente la experiencia del cliente.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Integraciones con terceros",
      description: "<p>Integra tus sistemas y herramientas existentes, como CRM, plataformas de análisis y otras aplicaciones de marketing. Gestiona todo desde un solo lugar y obtén una visión unificada de los datos del cliente, facilitando la coordinación de tus estrategias.</p>",
      image: ImageAccordionLeftFour,
    },
  ],
  bannerVideo: {
    title: "Cómo automatizar procesos en una empresa: 5 consejos claves",
    description: "<p>Descubre cómo optimizar la eficiencia de tu empresa con estos 5 consejos esenciales para la automatización de procesos. Simplifica operaciones, aumenta la productividad y libera tiempo para lo que realmente importa. <br /> <br /> Nuestro Project Manager, Jheyson Portocarrero, nos cuenta todos los detalles para automatizar cada proceso de tu empresa.</p>",
    image: imageVideo
  },
  industries: [
    {
      tab: "01. Banca y finanzas",
      title: "Campañas SMS para Banca, Seguros, Financieras y más",
      text: (
        <p>
          <br />
          Envía alertas automáticas de seguridad, como notificaciones de actividad inusual o autenticación de dos factores (2FA), para proteger las cuentas de tus clientes. Además, proporciona notificaciones en tiempo real sobre transacciones, como confirmaciones de pagos y depósitos.
          <br /> <br /> <br />
        </p>
      ),
      image: banca,
      active: "container-industries-channels-navbar-menu-button-active",
      link: "/hablemos/",
      textLink: "Conoce más de SMS para Banca y Finanzas",
    },
    {
      tab: "02. E-commerce",
      title: "Campañas SMS para E-commerce y Retail",
      text: (
        <p>
          <br />
          Impulsa tus ventas y recupera carritos abandonados. Envía promociones y descuentos exclusivos directamente a los teléfonos de los clientes para incentivar compras impulsivas. Además, envía recordatorios automáticos de carritos abandonados con enlaces directos al carrito.
          <br /> <br /> <br />
        </p>
      ),
      image: ecommerce,
      link: "/hablemos/",
      textLink: "Conoce más de SMS para E-commerce",
    },
    {
      tab: "03. Educación",
      title: "Campañas SMS para Universidades, Institutos y Colegios",
      text: (
        <p>
          <br />
          Mantén a estudiantes y padres informados y comprometidos. Envía recordatorios automáticos de clases, eventos y plazos importantes para asegurar que los estudiantes no pierdan ninguna actividad crucial. Además, utiliza SMS para comunicarte rápidamente sobre cambios de última hora, mejorando la eficiencia y efectividad de la comunicación institucional.
          <br /> <br /><br />
        </p>
      ),
      image: educacion,
      link: "/hablemos/",
      textLink: "Conoce más de SMS para Eduación",
    },
    {
      tab: "04. Inmobiliarias",
      title: "Campañas SMS para Inmobiliarias, Bienes y Raíces",
      text: (
        <p>
          <br />
          Envía actualizaciones automáticas sobre nuevas propiedades que coincidan con las preferencias de tus clientes. Además, realiza seguimientos de interés enviando recordatorios sobre visitas a propiedades, cambios de precio y otras actualizaciones relevantes.
          <br /> <br /><br />
        </p>
      ),
      image: inmobiliaria,
      link: "/hablemos/",
      textLink: "Conoce más de SMS para Inmobiliarias",
    },
    {
      tab: "05. Salud",
      title: "Campañas SMS para Clínicas, Hospitales y Centros de Salud",
      text: (
        <p>
          <br />
          Envía recordatorios automáticos de citas médicas para reducir las ausencias y mejorar la puntualidad de los pacientes. Además, proporciona información de salud personalizada y recordatorios de medicamentos.
          <br /> <br /><br />
        </p>
      ),
      image: salud,
      link: "/hablemos/",
      textLink: "Conoce más de SMS para Salud",
    },
    {
      tab: "06. Concesionarias",
      title: "Campañas SMS para Concesionarias",
      text: (
        <p>
          <br />
          Promociona anuncios de nuevos modelos de vehículos directamente a los teléfonos de tus clientes para atraerlos al concesionario. Además, envía recordatorios automáticos de mantenimiento programado.
          <br /> <br /><br />
        </p>
      ),
      image: concesionaria,
      link: "/hablemos/",
      textLink: "Conoce más de SMS para Concesionarias",
    },
  ],
  navbarVerticalRight: [
    {
      tab: "1",
      title: "Promociones y descuentos",
      text: (
        <p>
          Aumenta tus ventas y el compromiso del cliente enviando promociones y descuentos exclusivos a través de SMS. Aprovecha la alta tasa de apertura de los mensajes de texto para asegurarte de que tus ofertas lleguen directamente a los clientes.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Autenticación de cuentas",
      text: (
        <p>
          Mejora la seguridad de las cuentas de tus usuarios mediante la autenticación de dos factores (2FA) a través de SMS. Envía códigos de verificación directamente a los dispositivos móviles de los usuarios para proteger sus cuentas contra accesos no autorizados.
        </p>
      ),
      image: ImageVerticalTwo,
    },
    {
      tab: "3",
      title: "Mensajes transaccionales",
      text: (
        <p>
          Proporciona a tus clientes información importante y oportuna con mensajes transaccionales automatizados. Envía confirmaciones de pedidos, actualizaciones de envío y recibos de compra de forma inmediata, mejorando la transparencia y la confianza en tus procesos comerciales.
        </p>
      ),
      image: ImageVerticalThree,
    },
  ],
  navbarVerticalLeft: [
    {
      tab: "4",
      title: "Notificaciones personalizadas",
      text: (
        <p>
          Mantén a tus clientes informados y comprometidos con notificaciones personalizadas enviadas directamente a sus teléfonos. Personaliza tus mensajes en función de las preferencias y el comportamiento del cliente, asegurando que reciban información relevante y oportuna.
        </p>
      ),
      image: ImageVerticalFour,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
    {
      tab: "5",
      title: "Recordatorios a clientes",
      text: (
        <p>
          Reduce las ausencias y mejora la puntualidad con recordatorios automáticos a clientes. Envía recordatorios de citas, vencimientos de pagos y renovaciones de servicios, ayudando a tus clientes a mantenerse al tanto de sus compromisos y mejorando su experiencia general.
        </p>
      ),
      image: ImageVerticalFive,
    },
  ],
  navbarVerticalSilder: [
    {
      tab: "1",
      title: "Promociones y descuentos",
      text: (
        <p>
          Aumenta tus ventas y el compromiso del cliente enviando promociones y descuentos exclusivos a través de SMS. Aprovecha la alta tasa de apertura de los mensajes de texto para asegurarte de que tus ofertas lleguen directamente a los clientes.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Autenticación de cuentas",
      text: (
        <p>
          Mejora la seguridad de las cuentas de tus usuarios mediante la autenticación de dos factores (2FA) a través de SMS. Envía códigos de verificación directamente a los dispositivos móviles de los usuarios para proteger sus cuentas contra accesos no autorizados.
        </p>
      ),
      image: ImageVerticalTwo,
    },
    {
      tab: "3",
      title: "Mensajes transaccionales",
      text: (
        <p>
          Proporciona a tus clientes información importante y oportuna con mensajes transaccionales automatizados. Envía confirmaciones de pedidos, actualizaciones de envío y recibos de compra de forma inmediata, mejorando la transparencia y la confianza en tus procesos comerciales.
        </p>
      ),
      image: ImageVerticalThree,
    },
    {
      tab: "4",
      title: "Notificaciones personalizadas",
      text: (
        <p>
          Mantén a tus clientes informados y comprometidos con notificaciones personalizadas enviadas directamente a sus teléfonos. Personaliza tus mensajes en función de las preferencias y el comportamiento del cliente, asegurando que reciban información relevante y oportuna.
        </p>
      ),
      image: ImageVerticalFour,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
    {
      tab: "5",
      title: "Recordatorios a clientes",
      text: (
        <p>
          Reduce las ausencias y mejora la puntualidad con recordatorios automáticos a clientes. Envía recordatorios de citas, vencimientos de pagos y renovaciones de servicios, ayudando a tus clientes a mantenerse al tanto de sus compromisos y mejorando su experiencia general.
        </p>
      ),
      image: ImageVerticalFive,
    },
  ],
  conversions: [
    {
      title: "Crea estrategias A/B",
      img: imageConversionsOne,
      text:
        "Optimiza el rendimiento de tus campañas con pruebas A/B. Experimenta con diferentes versiones de tus mensajes SMS para determinar qué contenidos, tiempos de envío y estrategias funcionan mejor.",
    },
    {
      title: "Segmenta tus Envíos",
      img: imageConversionsTwo,
      text:
        "Dirige tus mensajes a los segmentos de audiencia más relevantes utilizando avanzadas herramientas de segmentación. Personaliza tus envíos según el comportamiento del cliente, datos demográficos, historial de compras y más, asegurando que cada mensaje sea relevante y efectivo.",
    },
    {
      title: "Potencia tus estrategias Opt-in",
      img: imageConversionsThree,
      text:
        "Construye y gestiona de manera eficiente tus listas de suscriptores con estrategias opt-in. Facilita a los usuarios la suscripción a tus mensajes SMS mediante formularios personalizados y flujos de suscripción simplificados.",
    },
    {
      title: "Enmascaramiento de números",
      img: imageConversionsFour,
      text:
        "Mejora la seguridad de tus comunicaciones con el enmascaramiento de números. Garantizamos que los mensajes SMS se envíen desde un número genérico o de marca, mejorando la percepción de tu empresa hacia los usuarios.",
    },
  ],
}

export default data
